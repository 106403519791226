import { useState } from 'react';
import danceImage from '../images/dance.webp';
import { Icon } from 'semantic-ui-react';

const pages = ['Home', 'Event Day', 'Register', 'Info', 'Donate'];

const menuLimit = 350;

const TopBar = ({page, setPage, menu, setMenu}) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    window.addEventListener('resize', () => {
        setScreenWidth(window.innerWidth);
    });

    const handleLinkClick = (pageName) => {
        setMenu(false);
        if (pageName !== page) setPage(pageName);
    }

    return (
        <>
            <div style={{width: '100%', height: window.innerHeight*.20, overflow: 'hidden', position: 'relative', marginBottom: 50}}>

                <img src={danceImage} alt="dancers" style={{position: 'absolute', bottom: (window.innerWidth < 500 ? 0 : -300), left: '50%', transform: 'translateX(-50%)', width: '100%', opacity: .4}} />
                <span style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, calc(-50% - 10px))', fontSize: 'clamp(16px, 3.6vw, 50px)', whiteSpace: 'nowrap'}}>2025 Phoenix Highland Dance Competition</span>
                <span style={{position: 'absolute', top: '50%', left: '50%', transform:'translate(-50%, calc(-50% + 2vw))', fontSize: 'clamp(16px, 3.6vw, 50px)', whiteSpace: 'nowrap'}}>02/15/2025</span>
                {screenWidth >= menuLimit &&
                    <div style={{position: 'absolute', bottom: 10, left: '50%', transform: 'translateX(-50%)', whiteSpace: 'nowrap'}}>
                        {pages.map((pageName, index) => {
                            return (
                                <div key={index} style={{display: 'inline-block', marginLeft: 20, marginRight: 20, fontSize: (window.innerWidth < 800 ? '3vw' : 20), fontWeight: 600, color: (pageName === page ? 'white' : '#444'), textShadow: '1px 1px #888', cursor: (pageName === page ? 'default' : 'pointer')}} onClick={() => handleLinkClick(pageName)}>
                                    {pageName}
                                </div>
                            );
                        })}
                    </div>
                }
                {screenWidth < menuLimit && <Icon name="bars" size="large" style={{position: 'absolute', top: 10, left: 10, cursor: 'pointer'}} onClick={() => setMenu(!menu)} />}
            </div>
            {menu &&
                <div style={{position: 'absolute', top: 40, right: 20, width: 150, backgroundColor: 'white', borderRadius: 3, border: '1px solid rgba(34,36,38,.15)', boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)'}}>
                    {pages.map((pageName, index) => {
                        return (
                            <div key={index} className={page === pageName ? 'disabledMenuOption' : 'activeMenuOption'} style={{padding: 10, fontSize: 14, fontWeight: 600, borderBottom: '1px solid rgba(34,36,38,.15)'}} onClick={() => handleLinkClick(pageName)}>{pageName}</div>
                        );
                    })}
                </div>
            }
        </>
    );
}

export default TopBar;