import { useEffect, useState } from 'react';
import 'semantic-ui-css/semantic.min.css'
import TopBar from './components/topbar';
import Home from './pages/home';
import Schedule from './pages/schedule';
import Location from './pages/location';
import Register from './pages/register';
import Donate from './pages/donate';
import Axios from 'axios';
import Spinner from './components/spinner';
import apiString from './components/apiString';
import Info from './pages/info';
import EventDay from './pages/eventday';

function App() {
	const [page, setPage] = useState('Home');
    const [menu, setMenu] = useState(false);
	const [cart, setCart] = useState([]);
	const [loading, setLoading] = useState(true);
	const [masterSwitch, setMasterSwitch] = useState(false);
	const [masterDanceList, setMasterDanceList] = useState([]);

	const pageClickHandler = newPage => {
		setPage(newPage);
		setMenu(false);
	}

	const addDancerToCart = dancer => {
		setCart([...cart, dancer]);
	}

	const removeDancerFromCart = dancerIndex => {
		const newCart = [...cart];
		newCart.splice(dancerIndex, 1);
		setCart(newCart);
	}

	const modifyDancerInCart = (dancerIndex, dancer) => {
		const newCart = [...cart];
		newCart[dancerIndex] = dancer;
		setCart(newCart);
	}

	useEffect(() => {
		/*const pulls = [];
		const masterSwitchData = new FormData();
		masterSwitchData.set('action', 'getMasterSwitch');
		pulls.push(Axios.post(apiString, masterSwitchData));*/

		const danceData = new FormData();
		danceData.set('action', 'getDanceList');
		// pulls.push(Axios.post(apiString, danceData));
		// Axios.all(pulls).then(response => {
		Axios.post(apiString, danceData).then(response => {
			// setMasterSwitch(response[0].data === 0 ? false : true);
			setMasterDanceList(response.data);

			pullMasterSwitch().then(() => {
				setLoading(false);
			});
			setInterval(() => {
				pullMasterSwitch();
			}, 5000);
		});
	}, []);

	const pullMasterSwitch = () => {
		return new Promise((resolve, reject) => {
			const masterSwitchData = new FormData();
			masterSwitchData.set('action', 'getMasterSwitch');
			Axios.post(apiString, masterSwitchData).then(response => {
				setMasterSwitch(response.data === 0 ? false : true);
				resolve();
			});
		});
	}

	const getCartTotal = () => {
		// let total = 5;
		let total = 0;
		for (let i = 0; i < cart.length; i++) {
			total += cart[i].total;
		}
		return total;
	}

	return (
		<>
			<div>
				<TopBar page={page} setPage={pageClickHandler} menu={menu} setMenu={setMenu} masterSwitch={masterSwitch} cartCount={cart.length} cartTotal={getCartTotal()} />
				{page === 'Home' && <Home />}
				{page === 'Event Day' && <EventDay setLoading={setLoading} />}
				{page === 'Register' && <Register masterDanceList={masterDanceList} addDancerToCart={addDancerToCart} removeDancerFromCart={removeDancerFromCart} modifyDancerInCart={modifyDancerInCart} cart={cart} cartTotal={getCartTotal()} setLoading={setLoading} setCart={setCart} masterSwitch={masterSwitch} />}
				{page === 'Location' && <Location />}
				{page === 'Schedule' && <Schedule />}
				{page === 'Info' && <Info />}
				{page === 'Donate' && <Donate setLoading={setLoading} />}
			</div>
			{loading && <Spinner text='Loading...' />}
		</>
	);
}

export default App;