import React, { useState } from 'react';
import { Button, Modal, Header, Form, Input } from 'semantic-ui-react';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import Axios from 'axios';
import apiString from './apiString';

const env = 'production';
// const env = 'sandbox';

//previous paypal account - highland games account i think
/*const client = {
    sandbox: 'AZ29y8PpNpAaBAaDNRtrFW3UCNm7uLBQ5SQjwgAigeS5-xIUt1w5sSneXcLhO8W2IkZ8K78HsYGu6Ews',
    production: 'AVWH2KOc-yvKsKwVUlMvOUw69lm2_WIj13fceUqOXSlVbfLJ63e0VWBr97upz2Mic7NoF3V4Gubny3JR'
};*/

//make believe
const client = {
    sandbox: 'AYRUK4Du20FrdXNPEHulaGvIo3faS62CSGLK2aNLHoke8fbAE0IilXqaUMAVvrhqgDU8IChZ5OyLa07d',
    production: 'ASoLXX1aZuAl2SJW1m-u9zmUyP_zkZTX8-EnqQxtGZdM9_9C1vZ9vThVTsPYECGAkIieUyOfqq4soYFg'
};
//secretprod: ECJnsNPev-64hnlQhp-e5iCnSsEz-oGGBkPTHic23-lffLK66EZ-UO_6m-LHv_Wg26WY-HjfKhUduEar
//secretdev: EDhoJ68jKvg_dz-s_YIzOU4iQgCpXsHapSmb5ciaYQryDaVDdRpytkvN-t5CeTVbySzUhWNIZIVTV4GG
const currency = 'USD';


const Checkout = ({setCheckoutModal, setLoading, cartTotal, cart, setCart}) => {
    const [contactInfo, setContactInfo] = useState({name: '', email: '', phoneNumber: '', amountPaid: cartTotal});
    const [donation, setDonation] = useState(0);
    const [page, setPage] = useState(1);

    const handleFormChange = (e, {id, value}) => {
        setContactInfo({...contactInfo, [id]: value});
    }

    const validateEmail  = () => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!regex.test(contactInfo.email)) setContactInfo({...contactInfo, email: ''});
    }

    const checkPage1Disabled = () => {
        return (contactInfo.name === '' || contactInfo.email === '' || contactInfo.phoneNumber === '');
    }

    const payPalError = error => {
        console.log(error);
        setPage(4);
    }

    const payPalCancel = data => {
        console.log(data);
    }

    const payPalSuccess = payment => {
        setLoading(true);
        const formData = new FormData();
        formData.set('action', 'enterRegistration');
        formData.set('donation', donation.toFixed(2));
        formData.set('contactInfo', JSON.stringify(contactInfo));
        formData.set('cart', JSON.stringify(cart));
        Axios.post(apiString, formData).then(() => {
            setPage(5);
            setLoading(false);
        });
    }

    const checkDonation = () => {
        if (isNaN(donation)) setDonation(0);
        else setDonation(+donation);
    }

    const clearDonation = () => {
        if (donation === 0) setDonation('');
    }

    const testClick = () => {
        // payPalSuccess();
    }

    return (
        <>
            {page === 1 &&
                <Modal size="small" open closeOnDimmerClick={false} closeOnEscape={false} closeIcon onClose={() => setCheckoutModal(false)}>
                    <Header as="h3" textAlign="center">Contact Info</Header>
                    <Modal.Content>
                        <Form style={{width: 250, margin: '0 auto'}}>
                            <Form.Field id="name" control={Input} value={contactInfo.name} label="Contact Name" fluid onChange={handleFormChange} />
                            <Form.Field id="email" control={Input} value={contactInfo.email} label="Contact Email" fluid onChange={handleFormChange} onBlur={validateEmail} />
                            <Form.Field id="phoneNumber" control={Input} value={contactInfo.phoneNumber} label="Contact Phone Number" fluid onChange={handleFormChange} />
                        </Form> 
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="blue" onClick={() => setPage(2)} disabled={checkPage1Disabled()}>Next</Button>
                        <Button negative onClick={() => setCheckoutModal(false)}>Cancel</Button>
                    </Modal.Actions>
                </Modal>
            }
            {page === 2 &&
                <Modal size="small" open closeOnDimmerClick={false} closeOnEscape={false} closeIcon onClose={() => setCheckoutModal(false)}>
                    <Header as="h3" textAlign="center">Donation</Header>
                    <Modal.Content>
                        <p>If you would like to make a donation, please enter the dollar amount here.</p>
                        <p>If you don't want to make a donation, just leave the amount at 0 and click "Next"</p>
                        <Form style={{width: 250, margin: '0 auto'}}>
                            <Form.Field id="donation" control={Input} value={donation} label="Donation $" fluid onChange={e => setDonation(e.target.value)} onBlur={checkDonation} onFocus={clearDonation} />
                        </Form> 
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="blue" onClick={() => setPage(1)}>Back</Button>
                        <Button color="blue" onClick={() => setPage(3)}>Next</Button>
                    </Modal.Actions>
                </Modal>
            }
            {page === 3 &&
                <Modal size="small" open closeOnDimmerClick={false} closeOnEscape={false} closeIcon onClose={() => setCheckoutModal(false)}>
                    <Header as="h3" textAlign="center" onClick={testClick}>Checkout</Header>
                    <Modal.Content>
                        <p style={{width: '100%', textAlign: 'center', marginBottom: 20}}>Click the Paypal button below to pay your total of <strong>${cartTotal + (donation === 0 ? 0 : +donation.toFixed(2))}</strong></p>
                        <div style={{width: 300, margin: '0 auto'}}>
                            <PaypalExpressBtn style={{color: 'blue', shape: 'rect', size: 'responsive', width: 200}} shipping="1" env={env} client={client} currency={currency} total={cartTotal + (donation === 0 ? 0 : +donation.toFixed(2))} onError={payPalError} onSuccess={payPalSuccess} onCancel={payPalCancel} />
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="blue" onClick={() => setPage(2)}>Back</Button>
                        <Button negative onClick={() => setCheckoutModal(false)}>Cancel</Button>
                    </Modal.Actions>
                </Modal>
            }
            {page === 4 &&
                <Modal size="small" open closeOnDimmerClick={false} closeOnEscape={false} closeIcon onClose={() => setCheckoutModal(false)}>
                    <Header as="h3" textAlign="center">Paypal Error</Header>
                    <Modal.Content>
                        <p>Paypal is not responding.  This usually means there is a connection issue on your end or something is wrong with Paypal.  You can try again by clicking the "Back" button below.</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="blue" onClick={() => setPage(3)}>Back</Button>
                        <Button negative onClick={() => setCheckoutModal(false)}>Cancel</Button>
                    </Modal.Actions>
                </Modal>
            }
            {page === 5 &&
                <Modal size="small" open closeOnDimmerClick={false} closeOnEscape={false} closeIcon onClose={() => {setCheckoutModal(false); setCart([]);}}>
                    <Header as="h3" textAlign="center">Registration Successful!</Header>
                    <Modal.Content>
                        <p>Your {(cart.length > 1 ? 'dancers are' : 'dancer is')} now registered for the Valley Of The Sun Highland Dance Competition.  We can't wait to see you in March!  If you have any questions, please reach out to: <a href="mailto:phxchampion@gmail.com">phxchampion@gmail.com</a>.</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="blue" onClick={() => {setCheckoutModal(false); setCart([]);}}>Got It!</Button>
                    </Modal.Actions>
                </Modal>
            }
        </>
    );
}

export default Checkout;