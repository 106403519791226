import React, { useState } from 'react';
import Contact from '../components/contact';
import { Button } from 'semantic-ui-react';
import DonationCheckout from '../components/donationcheckout';


const Donate = ({setLoading}) => {
    const [donateModal, setDonateModal] = useState(false);
    return (
        <div style={{padding: 20, margin: '0 auto', textAlign: 'center'}}>
            <p>If you would like to donate to the Valley Of The Sun Highland Dance Competition, you can click the button below:</p>
            <Button color="blue" style={{marginTop: 0}} onClick={() => setDonateModal(true)}>Donate!</Button>

            {donateModal && <DonationCheckout setDonateModal={setDonateModal} setLoading={setLoading} />}

            <Contact />
        </div>
    );
}

export default Donate;