import { useState } from "react";
import { Button } from "semantic-ui-react";
import Registration from "../components/registration";
import Cart from '../components/cart';
import Contact from '../components/contact';

const Register = ({masterDanceList, addDancerToCart, removeDancerFromCart, modifyDancerInCart, cart, cartTotal, setLoading, setCart, masterSwitch}) => {
    const [registrationModal, setRegistrationModal] = useState('');

    return (
        <div style={{padding: 20, margin: '0 auto', textAlign: 'center'}}>
            {masterSwitch &&
                <>
                    <p style={{marginTop: 0, fontSize: 22}}>You can register multiple dancers at once!</p>

                    <Button primary onClick={() => setRegistrationModal('new')}>Register{cart.length > 0 ? ' Another' : ''} Dancer</Button>

                    <Cart cart={cart} cartTotal={cartTotal} removeDancerFromCart={removeDancerFromCart} modifyDancerInCart={modifyDancerInCart} masterDanceList={masterDanceList} setLoading={setLoading} setCart={setCart} />


                    <Contact />

                    {registrationModal !== '' && <Registration setModal={setRegistrationModal} masterDanceList={masterDanceList} dancerIndex="new" addDancerToCart={addDancerToCart} cart={cart} />}
                </>
            }
            {!masterSwitch &&
                <p>Registration is not currently open.</p>
            }
            
        </div>
    );
}

export default Register;