import Contact from '../components/contact';
import logoImage from '../images/games-logo.jpg';

const Home = () => {
    return (
        <div style={{padding: 20, margin: '0 auto', textAlign: 'center'}}>
            <p>Welcome to the website for the Valley Of The Sun Highland Dance Competition held at at a private residence in Mesa, AZ.  The competition will take place on February 15th, 2025.</p>
            {/* <a href="https://phoenixscottishgames.com/" target="_blank" rel="noreferrer"><img src={logoImage} alt="games-logo" width="50%" /></a> */}
            <Contact />
        </div>
    );
}

export default Home;